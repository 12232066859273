import './App.scss';
import {useState, useEffect, createContext} from 'react';
import ErrorContainer from './error/ErrorContainer'
import Main from './main';
import Map from "./map"
import {isMobile} from './functions.js'
export const ThemeContext = createContext();

function App() {
const [theme,setTheme]=useState('black_theme');
//const [mobile,setMobile]=useState('');
useEffect(() => {
const getPreferredTheme=()=>window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'black_theme' : 'white_theme';
//document.body.className=''
if (!document.body.classList.contains('body_animate')) window.setTimeout(()=>{document.body.classList.add('body_animate')},50)
setTheme(getPreferredTheme)
  if (isMobile) document.body.classList.add('mobile_mode')
}, []);
useEffect(() => {
//  if  (document.body.className==='') document.body.className=theme;
//  else document.body.className="body_animate "+theme;
const listCls=document.body.classList
  listCls.forEach((el) =>
  {
    if ((el).indexOf('_theme')!==-1) document.body.classList.remove(el)
  })
document.body.classList.add(theme)
}, [theme]);
const [city,setCity]=useState(false)
const [gpoints,setGpoints]=useState(false)
const [points,setPoints]=useState(false)
const [popupPoints,setPopup]=useState(null)
const [closePoint,setClosePoint]=useState(undefined)
const [currentPoints,setCurrentPoints]=useState(undefined) //������� ����� ��� ������, ������ ����� ��������� ����������
const [updateNow, setUpdateNow]=useState(0) //��� ������������ ���������� ����� �����, �������� ��� ������� ���������������� ������ ����� ��� ��������� ��������
  return (
<ThemeContext.Provider value={{theme,setTheme}}>
    <div className="App">
<Map updateNow={updateNow}  x={city.latitude} y={city.longitude} zoom={city.zoom} arr={gpoints} icons={points} popup={(e,id,filterIds)=>popupPoints.showPointPopup(e,id,filterIds)} closePoint={closePoint} setCurrentPoints={setCurrentPoints} />
<ErrorContainer>
<Main setUpdateNow={setUpdateNow} setCity={setCity} setGpoints={setGpoints} setPoints={setPoints} setPopup={setPopup} setClosePoint={setClosePoint} currentPoints={currentPoints} setCurrentPoints={setCurrentPoints}   />
</ErrorContainer>
      </div>
</ThemeContext.Provider>
  );
}

export default App;
