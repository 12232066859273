// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dialog_error
{
color:#ffffff;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;	
position:absolute;
left:0px;
top:0px;
width:100%;
height:100%;
background-color:rgba(0,0,0,0.5);
z-index:40000;
}
#dialog_error textarea
{
position:absolute;
left:-200%;
top:-200%;
width:1px;
height:1px;
}
.dialog_error_wrapper
{
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
background-color:#151E39;
max-width:320px;
max-height:1000px;
width:100%;
padding:50px;
}
.dialog_error_title
{
font-weight:bold;
margin-bottom:15px;
}
.dialog_error_button
{
padding:10px;
background-color:#ffffff;
color:#000000;
cursor:pointer;
transition:0.3s all linear;
margin-top:30px;/*15*/
}
.dialog_error_button:HOVER
{
background-color:green;
color:#ffffff;
}
.dialog_error_close
{
position:absolute;
right:10px;
top:10px;
color:#ffffff;
cursor:pointer;
font-family: "inter";
font-size:15px;
font-weight:bold;
}`, "",{"version":3,"sources":["webpack://./src/error/error.css"],"names":[],"mappings":"AAAA;;AAEA,aAAa;AACb,YAAY;AACZ,qBAAqB;AACrB,kBAAkB;AAClB,sBAAsB;AACtB,iBAAiB;AACjB,QAAQ;AACR,OAAO;AACP,UAAU;AACV,WAAW;AACX,gCAAgC;AAChC,aAAa;AACb;AACA;;AAEA,iBAAiB;AACjB,UAAU;AACV,SAAS;AACT,SAAS;AACT,UAAU;AACV;AACA;;AAEA,iBAAiB;AACjB,YAAY;AACZ,qBAAqB;AACrB,kBAAkB;AAClB,sBAAsB;AACtB,wBAAwB;AACxB,eAAe;AACf,iBAAiB;AACjB,UAAU;AACV,YAAY;AACZ;AACA;;AAEA,gBAAgB;AAChB,kBAAkB;AAClB;AACA;;AAEA,YAAY;AACZ,wBAAwB;AACxB,aAAa;AACb,cAAc;AACd,0BAA0B;AAC1B,eAAe,CAAC,KAAK;AACrB;AACA;;AAEA,sBAAsB;AACtB,aAAa;AACb;AACA;;AAEA,iBAAiB;AACjB,UAAU;AACV,QAAQ;AACR,aAAa;AACb,cAAc;AACd,oBAAoB;AACpB,cAAc;AACd,gBAAgB;AAChB","sourcesContent":["#dialog_error\n{\ncolor:#ffffff;\ndisplay:flex;\nflex-direction:column;\nalign-items:center;\njustify-content:center;\t\nposition:absolute;\nleft:0px;\ntop:0px;\nwidth:100%;\nheight:100%;\nbackground-color:rgba(0,0,0,0.5);\nz-index:40000;\n}\n#dialog_error textarea\n{\nposition:absolute;\nleft:-200%;\ntop:-200%;\nwidth:1px;\nheight:1px;\n}\n.dialog_error_wrapper\n{\nposition:relative;\ndisplay:flex;\nflex-direction:column;\nalign-items:center;\njustify-content:center;\nbackground-color:#151E39;\nmax-width:320px;\nmax-height:1000px;\nwidth:100%;\npadding:50px;\n}\n.dialog_error_title\n{\nfont-weight:bold;\nmargin-bottom:15px;\n}\n.dialog_error_button\n{\npadding:10px;\nbackground-color:#ffffff;\ncolor:#000000;\ncursor:pointer;\ntransition:0.3s all linear;\nmargin-top:30px;/*15*/\n}\n.dialog_error_button:HOVER\n{\nbackground-color:green;\ncolor:#ffffff;\n}\n.dialog_error_close\n{\nposition:absolute;\nright:10px;\ntop:10px;\ncolor:#ffffff;\ncursor:pointer;\nfont-family: \"inter\";\nfont-size:15px;\nfont-weight:bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
