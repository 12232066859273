import {useEffect} from 'react';
var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua)) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
return this.split(search).join(replace);
}
export const encodeDataToURL=(data)=> 
{
return Object.keys(data).map(value => `${value}=${encodeURIComponent(data[value])}`).join('&');
 }
 //for animation scroll of div
const easing=(x)=>1-Math.pow(1 - x, 4)
export function animateDivScroll(target, left, duration)
{ 
let pos,start,aFrame
const maxScroll=target.scrollWidth-target.clientWidth
const init=target.scrollLeft
const amountToScroll=init-left;
  function step(curtime)
   {
  if (start===undefined) start=curtime
const elapsed=curtime-start
const progress=elapsed/duration
const eProgress=easing(progress)
pos=init-amountToScroll*Math.min(eProgress,1)
target.scrollTo({left:pos})
  if (init!==maxScroll && target.scrollLeft===maxScroll)
   {
window.cancelAnimationFrame(aFrame)
return
   }
  if (elapsed<duration) aFrame =window.requestAnimationFrame(step);
    }
aFrame=window.requestAnimationFrame(step);
}

export function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
export const delay=(x)=> 
{
return new Promise((resolve, reject)=>
  {
setTimeout(() => {resolve('')}, x)
  });
}