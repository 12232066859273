// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  min-height: 100vh;
  max-height: 100vh; }

/*animation*/
@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  99% {
    opacity: 0; }
  100% {
    display: none; } }

@keyframes fadeHide {
  0% {
    /*opacity:1;*/ }
  99% {
    opacity: 0; }
  100% {
    /*display:none !important;*/
    opacity: 0;
    pointer-events: none;
    visibility: hidden; } }

/*end of animation*/
.hidden {
  display: none !important; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EAEA,iBAAgB;EAChB,iBAAgB,EAAA;;AAEhB,YAAA;AACA;EACE;IACF,UAAS,EAAA;EAEP;IACF,UAAS,EAAA,EAAA;;AAGT;EACE;IACF,UAAS,EAAA;EAEP;IACF,UAAS,EAAA;EAEP;IACF,aAAY,EAAA,EAAA;;AAGX;EACC;IACF,aAAA,EAAc;EAEZ;IACF,UAAS,EAAA;EAEP;IACF,2BAAA;IACA,UAAS;IACT,oBAAmB;IACnB,kBACE,EAAA,EAAA;;AAEF,mBAAA;AACA;EAEA,wBAAuB,EAAA","sourcesContent":["body\n{\nmin-height:100vh;\nmax-height:100vh;\n}\n/*animation*/\n@keyframes fade {\n  0% {\nopacity:0;\n  }\n  100% {\nopacity:1;\n  }\n }\n@keyframes fadeOut {\n  0% {\nopacity:1;\n  }\n  99% {\nopacity:0;\n  }\n  100% {\ndisplay:none;\n  }\n }\n @keyframes fadeHide {\n  0% {\n/*opacity:1;*/\n  }\n  99% {\nopacity:0;\n  }\n  100% {\n/*display:none !important;*/\nopacity:0;\npointer-events:none;\nvisibility:hidden\n  }\n }\n/*end of animation*/\n.hidden\n{\ndisplay:none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
