import './error.css';
import { useEffect, useState } from 'react'

const ErrorDialog = (props) => {
  const [stringError, setStringError] = useState()

  useEffect(() => {
    setStringError(props.error?typeof props.error === 'string'?props.error:JSON.stringify(props.error, null, 2):null)
  }, [props.error])

return (
  <div onClose={props.onClose} open={props.error} id="dialog_error" onClick={(e)=>{
  if (e.target.id==='dialog_error') props.onClose()
}} >
  	  <div className="dialog_error_wrapper">
<div className="dialog_error_close" onClick={()=>{props.onClose()}}>X</div>
<div className="dialog_error_title">{props.title}</div>
<div className="dialog_error_subtitle">
Произошло что-то плохое. Сайт может работать некорректно.
<br /><br />Скопируйте детали ошибки и отправьте их команде поддержки, просто нажав на кнопку ниже.
</div>
        <textarea id="dialog_error_text" readOnly value={window.location+"\n"+props.title+"\n"+stringError}></textarea>
  <button className="dialog_error_button" onClick={()=>
{
const copyText = document.getElementById('dialog_error_text')
copyText.select()
document.execCommand('copy')
window.open("https://t.me/protone_support_bot","_blank")
props.onClose()
}}>Написать нам</button>
		</div>
    </div>
  )
}

export default ErrorDialog
