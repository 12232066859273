import './App.scss';
import {useState} from 'react';
import ErrorContainer from './error/ErrorContainer'
import Main from './main';
import Map from "./map"

function App() {
const [city,setCity]=useState(false)
const [gpoints,setGpoints]=useState(false)
const [points,setPoints]=useState(false)
const [popupPoints,setPopup]=useState(null)
const [closePoint,setClosePoint]=useState(undefined)
const [currentPoints,setCurrentPoints]=useState(undefined) //������� ����� ��� ������, ������ ����� ��������� ����������
  return (
    <div className="App">
<Map x={city.latitude} y={city.longitude} zoom={city.zoom} arr={gpoints} icons={points} popup={(e,id,filterIds)=>popupPoints.showPointPopup(e,id,filterIds)} closePoint={closePoint} setCurrentPoints={setCurrentPoints} />
<ErrorContainer>
<Main setCity={setCity} setGpoints={setGpoints} setPoints={setPoints} setPopup={setPopup} setClosePoint={setClosePoint} currentPoints={currentPoints} setCurrentPoints={setCurrentPoints}   />
</ErrorContainer>
      </div>
  );
}

export default App;
