import './map.scss';
import React, {useState, useEffect, useRef, useCallback, useMemo, memo} from "react";
import * as ReactDOM from 'react-dom';
//import {returnPointbyPointType} from "./api";
import {isMobile} from './functions.js'
import {encodeGeoHash} from "./geohash";
const ymaps3=window.ymaps3
const [ymaps3React] = await Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.ready]);
const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);
const {YMap, YMapDefaultSchemeLayer, YMapControls, YMapDefaultFeaturesLayer, YMapMarker, YMapFeatureDataSource, YMapLayer/*, BEHAVIOR, EventListener*/, YMapListener} =
reactify.module(ymaps3);
const {YMapZoomControl, YMapGeolocationControl} = reactify.module(await ymaps3.import('@yandex/ymaps3-controls@0.0.1'));
const {YMapClusterer, clusterByGrid} = reactify.module(await ymaps3.import('@yandex/ymaps3-clusterer@0.0.1'));
//const {BehaviorMapEventHandler} = reactify.module(await ymaps3.import('@yandex/ymaps3-controls-extra'));
let timerUpdateCoord;
function Cluster(props)
{
	//console.log(props)

const [current,setCurrent]=useState(0)
const gridSizedMethod = useMemo(() => clusterByGrid({gridSize: 64}), []);
const refCurrent=useRef(null)
refCurrent.current=current
const open=useCallback((e,click,id,mergedIds)=>
{
const supportTouch=(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
const mobile=(/*window.innerWidth<=768 || */isMobile)?true:false;
  if ((mobile || supportTouch) && click===true) return;
  //console.log(id)
//  console.log(filterIds)
setCurrent(id);
props.popup(e,id,mergedIds)
},[props])

const marker = useCallback(
  (feature) => (
    <YMapMarker coordinates={feature.geometry.coordinates} source={'my-source'}>
    <section className="map_section" onTouchStart={(e)=>{window.setTimeout((e)=>{open(e,false,feature.properties.id)},50,e)}}  onClick={(e)=>{open(e,true,feature.properties.id,feature.properties.mergedIds)}}>
    <div  style={{width:33,height:'auto'}} onClick={(e)=>e.target.parentNode.classList.add('map_selected')} className={(refCurrent.current===feature.properties.id)?'map_selected':'map_div'}>
<img className="map_icon" src={feature.properties.icon}  alt=""/>
	</div>
    </section>

    </YMapMarker>
  ),
  [open]
);
const cluster = useCallback(
  (coordinates, features) => 
{
  const clickMe=()=>
  {
props.setCoord({x:coordinates[0],y:coordinates[1],zoom:16})
//props.setZoom(16);
//console.log(props.getcoord())
 // 	  console.log(window.ymaps3)
//const map=window.ymaps3.YMap;

	  //console.log(props.map)
  	  	  /*
window.ymaps3.geocode('������, ����� ���� ��������, 16').then(function (res) {
    var coords = res.geoObjects.get(0).geometry.getCoordinates();
    map.zoomRange.get(coords).then(function (range) {
        map.setCenter(coords, range[1]);
    });
});	  */
  }
   return (
    <YMapMarker coordinates={coordinates} source={'my-source'}>
<div className="circle" onClick={clickMe}>
        <div className="circle-content">
          <span className="circle-text">{features.length}</span>
        </div>
      </div>
    </YMapMarker>
  )}, // eslint-disable-next-line react-hooks/exhaustive-deps
  []
);
const coordinates = props.arr.map((item)=>{return [item.longitude,item.latitude]})
	
const points = coordinates.map((lnglat, i) => 
({
  type: 'Feature',
  id: i,
  geometry: {coordinates: lnglat},
properties: {id:props.arr[i].id,icon:props.arr[i].icon,mergedIds:props.arr[i].mergedIds}
})
);
return(
        <YMapClusterer
            method={gridSizedMethod}
            features={points}
            marker={marker}
             cluster={cluster}
        />
)
}



const Map=memo((props)=>
{
            // Status of behavior events
           /*  const [behaviorEvents, setBehaviorEvents] = useState({
                  scrollZoom: false,
                  drag: false,
                  mouseRotate: false,
                  mouseTilt: false
              });*/
const refMap = React.useRef(null);
//const [zoom, setZoom] = useState(13);
const [coord,setCoord]=useState({x:props.x,y:props.y,zoom:props.zoom})
const setMapRef = useCallback((e) => {
  refMap.current = e;
}, []);
useEffect(() => {
//	console.log(props.x,props.y)
setCoord({...coord,x:props.x,y:props.y,zoom:props.zoom})
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.x,props.y]);


const getCamera = () => {
	// console.log(refMap.current)
    return new Promise((resolve, reject) => {
      if (refMap.current) {
      	 
        refMap.current.getCameraPosition((position) => {
          resolve(position);
        });
      } else {
        reject('ERROR');
      }
    })

}


  // Function that creates a handler function to change the status of behavior event
              const createBehaviorEventHandler = useCallback((isStart: boolean): BehaviorMapEventHandler => {
                  return function (object) {
                      if (object.type === 'dblClick') return;

                      if (isStart) {
                      if (object.type === 'scrollZoom')  {
                      	 // if (typeof props.closePoint!=='undefined')  props.closePoint.setShowPointInfo(false)
/*const el=document.getElementsByClassName('map_selected')
  if (el.length>=1) 
   {
el[0].classList.add('map_div')
el[0].classList.remove('map_selected')
   }*/
                      	 //  console.log('actionStart object: ', object,props.closePoint);
                      return}
                       
                      
                      } 
                      /*else {
                          //console.log('actionEnd object: ', object);
                      }*/

                   //   behaviorEvents[object.type] = isStart;
                  //    setBehaviorEvents({...behaviorEvents});
                  };// eslint-disable-next-line react-hooks/exhaustive-deps
              },[]);
              
              // Handler function for changing the status of the onUpdate event
              const updateHandler: MapEventUpdateHandler = useCallback((object) => {

const geohash=encodeGeoHash(refMap.current.center[1],refMap.current.center[0],4)/*9*/
	
  if (typeof refMap.current!=='undefined' && refMap.current.center[1]!==props.y && refMap.current.center[0]!==props.x) 
   {
window.clearTimeout(timerUpdateCoord)
timerUpdateCoord=window.setTimeout(function(){setCoord({x:refMap.current.center[0],y:refMap.current.center[1],zoom:object.location.zoom})},100)
 }

//console.log(geohash)
function returnArrFiltered(geohash)
{
let rez=[];
//geohash=geohash.substr(0,4)

   for (let i=0 ; i<props.arr.length; i++)
   { 
 //     if (props.arr[i].geohash2.substr(0,4)===geohash) rez.push(props.arr[i])
       if (props.arr[i].geohash2===geohash) 
        {
const item=props.arr[i]
item.distance=Math.acos(Math.sin(refMap.current.center[1]*3.14159/180)*Math.sin(item.latitude*3.14159/180)+Math.cos(refMap.current.center[1]*3.14159/180)*Math.cos(item.latitude*3.14159/180)*Math.cos((refMap.current.center[0]-item.longitude)*3.14159/180))*6371.21
//item.distance=Math.acos(Math.sin(refMap.current.center[0]*3.14159/180)*Math.sin(item.latitude*3.14159/180)+Math.cos(refMap.current.center[0]*3.14159/180)*Math.cos(item.latitude*3.14159/180)*Math.cos((refMap.current.center[1]-item.longitude)*3.14159/180))*6371.21
rez.push(item)
        }
    }
    //console.log(geohash,props.arr[0].geohash,encodeGeoHash(refMap.current.center[0],refMap.current.center[1],4))
rez.sort((a, b) => a.distance - b.distance);
props.setCurrentPoints(rez)
//console.log(rez)
}

if (props.arr.length>0 && refMap.current!==null) returnArrFiltered(geohash)
//                  console.log('Update object: ', object);
//ymaps3.geoQuery(ymaps3.geocode('�����-���������'));
//  	      var bounds = refMap.current.getBounds();
  //	      console.log(bounds)
   /* var contains = ymaps.util.bounds.containsPoint(bounds, item.center);
    if (contains) {
    	
    }*/
               //   object.mapInAction ? (mapEvents.update = true) : (mapEvents.update = false);
                //  setMapEvents({...mapEvents});
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [refMap.current,props.arr.length]);

	if (typeof props.x==='undefined' || typeof props.arr==='undefined' || props.arr===false) return ''
//console.log(props)
//console.log(returnPointbyPointType(props.icons,props.arr[0].pointOfInterestTypeId))
//console.log(points)
  return (
  	  <div className="map">
	<YMap location={{center: [coord.x, coord.y], zoom: (typeof coord.zoom!=='undefined')?coord.zoom:13}} mode="vector" ref={setMapRef} zoomRange={{min:5,max:21}}    >
  <YMapControls position="right">
                <YMapZoomControl    />
<YMapGeolocationControl position="right" />
              </YMapControls>
<YMapListener
			onActionStart={createBehaviorEventHandler(true)}
                /*    onActionEnd={createBehaviorEventHandler(false)}*/
                	onUpdate={updateHandler}
                      />
  <YMapDefaultSchemeLayer />
  <YMapDefaultFeaturesLayer />
        <YMapFeatureDataSource id="my-source"/>
        <YMapLayer source="my-source" type="markers" zIndex={1800}  />
	  			<Cluster popup={props.popup} arr={props.arr}  icons={props.icons} map={refMap.current} getcoord={getCamera} /*setZoom={setZoom}*/ setCoord={setCoord} />
  			{/*	<YMapClusterer method={gridSizedMethod} features={temp} /> */}
  					
  					

</YMap>
	  </div>
  );
},(prevProps, nextProps) => (prevProps.arr.length === nextProps.arr.length && prevProps.x===nextProps.x))
//<Markers arr={props.arr} icons={props.icons} popup={props.popup} setcoord={setCoord} />
export default Map;