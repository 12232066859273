// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./i/white/loader.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./i/black/loader.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  color: var(--primary-color);
  background-color: var(--bg-color);
  min-height: 100vh;
  max-height: 100vh;
  background-position: center;
  background-repeat: no-repeat; }

.white_theme {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }

.black_theme {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}); }

.body_animate {
  transition: 0.3s background-color linear; }

.body_animate DIV {
  transition: 0.3s color linear; }

/*animation*/
@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  99% {
    opacity: 0; }
  100% {
    display: none; } }

@keyframes fadeHide {
  0% {
    /*opacity:1;*/ }
  99% {
    opacity: 0; }
  100% {
    /*display:none !important;*/
    opacity: 0;
    pointer-events: none;
    visibility: hidden; } }

/*end of animation*/
.hidden {
  display: none !important; }

.disable_scroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EAEA,2BAA0B;EAC1B,iCAAgC;EAChC,iBAAgB;EAChB,iBAAgB;EAChB,2BAA0B;EAC1B,4BAA2B,EAAA;;AAE3B;EAEA,yDAA4C,EAAA;;AAE5C;EAEA,yDAA4C,EAAA;;AAE5C;EAEA,wCAAuC,EAAA;;AAEvC;EAEA,6BAA4B,EAAA;;AAE5B,YAAA;AACA;EACE;IACF,UAAS,EAAA;EAEP;IACF,UAAS,EAAA,EAAA;;AAGT;EACE;IACF,UAAS,EAAA;EAEP;IACF,UAAS,EAAA;EAEP;IACF,aAAY,EAAA,EAAA;;AAGX;EACC;IACF,aAAA,EAAc;EAEZ;IACF,UAAS,EAAA;EAEP;IACF,2BAAA;IACA,UAAS;IACT,oBAAmB;IACnB,kBACE,EAAA,EAAA;;AAEF,mBAAA;AACA;EAEA,wBAAuB,EAAA;;AAEvB;EAEA,6BAA4B;EAC5B,6BAA4B,EAAA","sourcesContent":["body\n{\ncolor:var(--primary-color);\nbackground-color:var(--bg-color);\nmin-height:100vh;\nmax-height:100vh;\nbackground-position:center;\nbackground-repeat:no-repeat;\n}\n.white_theme\n{\nbackground-image:url('./i/white/loader.svg');\n}\n.black_theme\n{\nbackground-image:url('./i/black/loader.svg');\n}\n.body_animate\n{\ntransition:0.3s background-color linear;\n}\n.body_animate DIV\n{\ntransition:0.3s color linear;\n}\n/*animation*/\n@keyframes fade {\n  0% {\nopacity:0;\n  }\n  100% {\nopacity:1;\n  }\n }\n@keyframes fadeOut {\n  0% {\nopacity:1;\n  }\n  99% {\nopacity:0;\n  }\n  100% {\ndisplay:none;\n  }\n }\n @keyframes fadeHide {\n  0% {\n/*opacity:1;*/\n  }\n  99% {\nopacity:0;\n  }\n  100% {\n/*display:none !important;*/\nopacity:0;\npointer-events:none;\nvisibility:hidden\n  }\n }\n/*end of animation*/\n.hidden\n{\ndisplay:none !important;\n}\n.disable_scroll\n{\noverflow-x:hidden !important;\noverflow-y:hidden !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
