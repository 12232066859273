function excludeArray(array1, excludedData)
{
return  array1.filter(item => !excludedData.includes(item));
}
function filterReducer(state,action)
{
  switch (action.type) {
    case 'init': //init elements
    {
return {...state, items:action.arr}
     }
    case 'toggle': //toggle one element
    {
let items=state.items
      if (items.indexOf(action.id)==-1) 
       {
const newItems = [...items, action.id];
return {...state, items:newItems}
       }
      else 
       {
const newItems = items.filter(c=>c!=action.id)
return {...state, items:newItems}
        }
     }
    case 'toggle_all': //toggle all of elements, if one+ is appear - disable all, otherwise - enable all
    {
let items=state.items
let newItems
let toggle_on=true
      for (let num of action.ids)
       {
         if (items.indexOf(num)!=-1) 
          {
toggle_on=false
break
          }
       }
newItems=excludeArray(items,action.ids)
  if (toggle_on===true) newItems=[...items,...action.ids]
return {...state, items:newItems}
       }
default: { throw new Error('Unknown action: '+action.type) }
  }
}

export default filterReducer;