import './main.scss';
import useErrorHandler from './error/useErrorHandler'
import {useState,useEffect,useRef,useReducer,createContext/*, useContext*/, forwardRef} from 'react';
import {/*setError,*/getGlobalPoints,getPoint/*,returnNamebyPointType*/,getFilters,getPartners,getAllPartners} from "./api";
import {animateDivScroll,useEvent,isMobile,delay} from './functions.js'
import Images from "./image"
import popupReducer from "./popup_reducer"
import filterReducer from "./filter_reducer"
import menuReducer from "./menu_reducer"
/*import Map from "./map"*/
var classNames = require('classnames');
const OurContext = createContext();



//const ImagesPath = './i/';

const ApiMode=()=>
{
const h=window.location.hash
let s='';
  if (h==='') return '';
  else if (h==='#alpha') s='alpha'
  else if (h==='#beta') s='beta'
  else if (h==='#gamma') s='gamma'
  else if (h==='#delta') s='delta'
  else if (h==='#dev') s='dev'
return(
  <div className="api_mode">{s}</div>	
)
}




const Slider=forwardRef(function Slider(props,ref)
{
const thumbScroll=useRef(null);
const [current,setCurrent]=useState(parseInt(props.current))
const [fireArrow,setFireArrow]=useState({goLeft(){return false},goRight(){return false}})
useEffect(() => {
if (thumbScroll.current===null) return
const scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-25
/*thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "smooth"
});*/
  animateDivScroll(thumbScroll.current,scrollTo,1000)
props.apiEventsSend({event_name:"impact_map_element_picture_click"}); // eslint-disable-next-line react-hooks/exhaustive-deps
}, [current]);
useEffect(() => {
window.setTimeout(function(){setCurrent(parseInt(props.current))},100) // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);
  if (props.show===false || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
   thumbs.push(<img src={props.aimg[i].preview} alt="" key={"thumb_"+i} onClick={(e)=>{(e).stopPropagation();setCurrent(parseInt(temp))}} className={classNames("image",{"image_act":(current===i)?true:false})} />)
   }
//
const scroller=(e)=>
{
let scrollTo;
e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
//animateDivScroll(thumbScroll.current,scrollTo,1000)
}

const closeMe=()=>
{
props.close()
}

return(
<div className="wrapper_gallery" ref={ref}  >
				<div className="panel_gallery" onClick={(e)=>{if (e.target.className.indexOf('panel_gallery')!==-1) closeMe()}}>
<div className="close" onClick={()=>closeMe()} />
<img alt="" src="./i/arrow_left.svg" className="arrow_left" onClick={()=>fireArrow.goLeft()} />
<img alt="" src="./i/arrow_left.svg"  className="arrow_right" onClick={()=>fireArrow.goRight()} />
<div className="slider_container"><Images current={current} setcur={(e)=>{setCurrent(e)}} close={closeMe} arr={props.aimg} arrows={true} swipe={true} click="once" setArrow={setFireArrow} /></div>
{/*  <div className="slider_text">{current+1}<span>/</span>{props.aimg.length}</div>*/}
<div className="images_wrapper"><div className="images" ref={thumbScroll} onWheel={(e)=>{scroller(e);}}>
{thumbs}
</div></div>
				</div>
</div>
)
})

const BottomMenu=()=>
{
return(
	<div className="bottom_menu">
		<a href="https://protone.app" rel="noreferrer" className="item">Сайт</a>
		<a href="https://protone.app/#about" rel="noreferrer" className="item">О нас</a>
		<a href="https://protone.app/#how" rel="noreferrer" className="item">Как это работает?</a>
		<a href="https://store.protone.app/" rel="noreferrer" className="item">Магазин Наград</a>
		{/*<a href="#main" rel="noreferrer" className="item">Protone media</a>
		<a href="#main" rel="noreferrer" className="item">Protone app</a>
		<a href="#main" rel="noreferrer" className="item">Кейсы</a>
		<a href="#main" rel="noreferrer" className="item">Партнеры</a>*/}
		<a href="#main" rel="noreferrer" className="item item_act">Импакт-карта</a>
		<a href="#footer" rel="noreferrer" className="item">Контакты</a>
		<div className="menu_soc"><a href="https://t.me/protone" rel="noreferrer" target="_blank"><img src="./i/soc_teleg.svg" alt="" className="soc" /></a><a href="https://www.tiktok.com/@protone.app" rel="noreferrer" target="_blank"><img src="./i/soc_tik.svg" alt="" className="soc" /></a><a href="https://www.vk.com/protone_app" rel="noreferrer"  target="_blank"><img src="./i/soc_vk.svg" alt="" className="soc" /></a></div>
	</div>
)
}

const Filter=(props)=>
{
const [isShow,setIsShow]=useState(false)
let icon='';
  switch (props.title)
   {
    case 'Утилизация':
    {
icon='./i/icon_util.svg';
break
    }
    case 'Арт-объекты':
    {
icon='./i/icon_art.svg';
break
    }
    case 'Знания': 
    {
icon='./i/icon_know.svg';
break
    }
    default: { icon='./i/icon_know.svg';}
   }
const toggle_all=()=>
{
let rez=[]
props.arr.map((item)=>rez.push(item.filterId))
props.set({type:'toggle_all',ids:rez})	
}
    useEffect(() => {
let allId=[]
props.arr.map((item)=>allId.push(item.filterId))
  if (props.active.filter(item => !allId.includes(item)).length===props.active.length) setIsShow(true)
  else setIsShow(false) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active])
return(
	<div className="menu_wrapper">
		<div className="wrapper">
		<div className="top">
			<img src={icon} alt="" />
			<div className="title">{props.title}</div>
		</div>
	<div className="show" onClick={toggle_all}>{(isShow)?"Показать все":"Скрыть все"}</div>
		</div>
		<div className="items">
{props.arr.map((item,i)=>{
return <div className={classNames("item",{"item_act":(props.active.indexOf(item.filterId)!==-1)?true:false})} id={item.filterId} key={i} onClick={()=>props.set({type:'toggle',id:item.filterId})}>{item.title}</div>
})}
		</div>
	</div>
)
}

const Filters=(props)=>
{
//const list = Object.keys(props.arr);
const arr=props.arr
let rez=[]
  for (let property in arr) 
   {
  rez.push(<Filter key={property} title={property} arr={arr[property].points} active={props.active} set={props.set} />)
   }
return (
<div>
{rez}
</div>
)
}

const Partner=(props)=>
{
const [isShow,setIsShow]=useState(true)
/*
const toggle_all=()=>
{
let rez=[]
props.arr.map((item)=>rez.push(item.partnerId))
props.set({type:'toggle_all',ids:rez})	
}
    useEffect(() => {
let allId=[]
props.arr.map((item)=>allId.push(item.partnerId))
  if (props.active.filter(item => !allId.includes(item)).length===props.active.length) setIsShow(true)
  else setIsShow(false)
  }, [props.active])
 */

 
return(
	<div className="menu_wrapper">
		<div className="wrapper wrapper_partner">
		<div className="top">
			<div className="title">{props.title}</div>
		</div>
	<div className="show"  onClick={()=>setIsShow(!isShow)}>{(!isShow)?"Показать фильтр":"Скрыть фильтр"}</div>
		</div>
		<div className={classNames("items",{"hidden":!isShow})}>
{props.arr.map((item,i)=>{
return <div className={classNames("item",{"item_act":(props.active.indexOf(item.partnerId)!==-1)?true:false})} id={item.padrtnerId} key={i} onClick={()=>{props.set({type:'toggle',id:item.partnerId});props.setPartnersFire(Math.random())}}>{item.title}</div>
})}
		</div>
	</div>
)
}

const Partners=(props)=>
{
//const list = Object.keys(props.arr);
const arr=props.arr
let rez=[]
  for (let property in arr) 
   {
  rez.push(<Partner key={property} title={property} arr={arr[property].points} active={props.active} set={props.set} setPartnersFire={props.setPartnersFire} />)
   }
return (
<div>
{rez}
</div>
)
}
const PointPanelItem=(props)=>
{
const title=(typeof props.title=='undefined' || props.title===null)?'':props.title
const address=(typeof props.address=='undefined' || props.address===null)?'':props.address
const img=(typeof props.img=='undefined' || props.img===null)?'':<img src={props.img} alt="" />
const count=(typeof props.count=='undefined' || props.count===null || props.count===0)?'Нет фотографий':props.count+' фотографий'
const work=(typeof props.work=='undefined' || props.work===null)?'':props.work
return(
<div className="point_list_item" data-ident={props.id} onClick={(e)=>{props.setCity({longitude:props.latitude,latitude:props.longitude,zoom:16});props.set(e)}}>
	<div className="image">
{img}
	</div>
	<div className="wrapper">
{		(title!=='')?<div className="title">{props.title}</div>:''}
{		(address!=='')?<div className="address" title={props.address}>{props.address}</div>:''}
		<div className="bottom">
			<div className="work">{work}</div>
			<div className="count">{count}</div>
		</div>
	</div>

</div>
)
}
const PointsPanel=(props)=>
{
const arr=props.arr
let rez=[]
  for (var i=0; i<arr.length; i++) 
   {
  rez.push(<PointPanelItem setCity={props.setCity} longitude={arr[i].longitude} latitude={arr[i].latitude} count={arr[i].countContent} key={i} title={arr[i].description} address={arr[i].name} work={arr[i].work} img={arr[i].img} set={props.set} id={arr[i].id} />)
   }
return (
<div className="point_list">
{rez}
</div>	
)
}

function Screen1(props)
{
const openScreen2=()=>
{
getGlobalPoints(props.setGpoints,props.filters.items,null,props.arrAllFilters)
props.getPartners(props.setArrPartners,props.filters.items)
props.setMenu({type:'show_partners'})
}
return(
<div>
	<div className="menu_top menu_sticky">
		<div className="wrapper">
		<a href="https://protone.app" rel="noreferrer"><img src="./i/logo.svg" alt="Protone" className="logo" /></a>
		<img src="./i/close.svg" alt="" className="close" onClick={props.setClose} />
		</div>
		<div className="menu_hr"></div>
	</div>
	<div className="menu_top">
		<div className="title">Фильтр локаций</div>
	</div>	
{(props.arrFilters!==false)?<Filters arr={props.arrFilters} active={props.filters.items} set={props.setFilters} />:''}
<div className={classNames("menu_button",{"menu_button_act":(props.filters.items.length>0)?true:false})}  onClick={openScreen2}>Применить</div>
<div className="menu_hr menu_hr_bottom"></div>
<BottomMenu />
</div>
	)
}

function Screen2(props)
{
	/*{
    "id": 145,
    "latitude": 55.8675957,
    "longitude": 37.53138,
    "distance": 1.418131881559125,
    "filterIds": [],
    "partnerId": null,
    "countContent": 1,
    "work": null,
    "geohash": "ucfty",
    "mergedIds": [],
    "geohash2": "tnxt",
    "icon": "./i/temp_point.png"
}*/
let arr_points=props.currentPoints
arr_points.forEach(aPoint=>
{
const partner = props.arrAllPartners.find(item => item.partnerId === aPoint.partnerId)
aPoint.img=(typeof partner!=='undefined') ?partner.img:'./i/temp_partner.png'
})
/*
const arr_points=[
{id:1,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:2,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
{id:3,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:4,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
{id:5,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:6,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
{id:7,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:8,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
{id:9,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:10,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
{id:11,img:'./i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:12,img:'./i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
]*/
const openScreen1=()=>
{
//getGlobalPoints(props.setGpoints,props.filters.items,null,props.arrAllFilters)
props.setFilters({type:'init',arr:[]}) //очистим фильтры
	//console.log(props)
getGlobalPoints(props.setGpoints,null,null,props.arrFilters)
props.setMenu({type:'show_filters'})
}
const list = Object.keys(props.arrPartners);
let currentFilter
  if (props.filters.items.length===1)
   {
currentFilter=props.arrFilters.filter(item=>props.filters.items[0]===item.filterId)
currentFilter=currentFilter[0].owner+': '+currentFilter[0].title
   }
   else
   {
currentFilter=`Фильтры (${props.filters.items.length})`
   }
const openPoint=(e)=>
{
const point=arr_points.find(point => parseInt(point.id) ===parseInt(e.currentTarget.dataset.ident))
props.showPointPopup(e,parseInt(e.currentTarget.dataset.ident),point.mergedIds);props.setMenu({type:'show_point'})
}
return(
<div>
	<div className="menu_top menu_sticky">
		<div className="wrapper">
<img src="./i/back.svg" alt="" className="back" onClick={openScreen1} />
<div className="top_title">{currentFilter}</div>
<img src="./i/close.svg" alt="" className="close" onClick={props.setClose} />
		</div>
{(props.arrPartners!==false && typeof props.arrPartners[list[0]]!=='undefined' && typeof props.arrPartners[list[0]].points!=='undefined' && props.arrPartners[list[0]].points.length!==0)?<Partners setPartnersFire={props.setPartnersFire} arr={props.arrPartners} active={props.partners.items} set={props.setPartners} />:''}
		<div className="menu_hr menu_hr_partner"></div>
	</div>
	<div className="menu_top"> 
<PointsPanel set={(e)=>{openPoint(e)}} arr={arr_points}  setCity={props.setCity} />
	</div>	

</div>
	)
}

function ScrollableDivWithScroll(props) {

  const scrollableDivRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = e => {
  if ('PointerEvents' in window) return true
    setIsDragging(true);
    setStartX(e.clientX);
const all=scrollableDivRef.current.querySelectorAll('*')
for (var i=0; i<all.length; i++ ){all[i].classList.add('no_pointer')}
  };

  const handleMouseUp = () => {
  if ('PointerEvents' in window) return true
    setIsDragging(false);
const all=scrollableDivRef.current.querySelectorAll('*')
for (var i=0; i<all.length; i++ ){all[i].classList.remove('no_pointer')}
  };

  const handleMouseMove = e => {
  if ('PointerEvents' in window) return true
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
    scrollableDivRef.current.scrollTo({ left: scrollableDivRef.current.scrollLeft + deltaX, behavior: "smooth" });
  };

  return (
    <div className="one_point_filters_wrapper_top"  ref={scrollableDivRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
      <div className="one_point_filters_wrapper">
{props.items}
      </div>
    </div>
  );
}

function Screen3(props)
{
	function SmallImages(props)
	{
const count=props.count; //max count of images
let i=0,rez=[]
const {arr}=props
  while (i!==count) 
   {
let temp=i
rez.push(<div className="image_block" key={"smallimage"+i} onClick={()=>props.setCur(temp)}><div className="image_block_image" style={{backgroundImage:"url('"+arr[i].preview+"')"}}  /></div>)
i++
   if (arr.length-1<i) break
   }
//add text information of count of images
let temp=i
if (arr.length>count) rez.push(<div className="image_block" key={"smallimage"+i}  onClick={()=>props.setCur(temp)}><div className="image_block_image"  style={{backgroundImage:"url('"+arr[i].preview+"')"}} />{(arr.length-count-1!==0)?<div className="text">+{arr.length-count-1}</div>:''}</div>)
//
return(
<div className="one_point_images">
{rez}
</div>
)
	}
  	function Work(props)
  	{
const arr=props.work.split('|');
let rez=[]
  for (var i=0; i<arr.length; i++) 
   {
const item=arr[i].split(': ');
rez.push(<div className="one_point_wrapper" key={i}>
				<div className="one_point_small_text">{item[0]}</div>
				<div className="one_point_small_text">{item[1]}</div>
			</div>
)
   }
return(
				<div className="one_point_top_wrapper">
			<div className="one_point_title_work">Часы работы:</div>
{rez}
				</div>
)
  	}
	function Partner(props)
	{
const item = props.partners.find(point => parseInt(point.partnerId) ===parseInt(props.id));
return(
			<div className="one_point_top_wrapper one_point_wrapper">
				<img src={item.img} alt="" className="one_point_partner" />
<div className="one_point_big_text">{item.title}</div>
			</div>	
)
	}
	function returnTextDescription(s) //replace line break to html tags
	{
return s.replaceAll("/r/n","<br />").replaceAll("/n/r","<br />").replaceAll("/r","<br />").replace(/[\.|\; |:]([А-Я])/g, "<br /><br />$1");
	}

if (props.point===false || props.partners===false || props.filters===false) return 'Загрузка...';


const filters=props.point.filterIds.map((item,i)=>{
const filter=props.filters.find(el=> parseInt(el.filterId) ===parseInt(item));
return <img src={filter.img} title={filter.title} alt={filter.title} className="one_point_filter" key={i}  />
})
const address=(props.point.address===null)?'':<div className="one_point_top_wrapper one_point_title">{props.point.address}</div>
return(
<div>
	<div className="menu_top menu_sticky">
		<div className="wrapper">
<img src="./i/back.svg" alt="" className="back" onClick={props.setClose} />
<div className="top_title">{props.point.name}</div>
{/*<img src="./i/close.svg" alt="" className="close" onClick={props.setClose} />*/}
		</div>
		<div className="menu_hr menu_hr_point"></div>
	</div>
	<div className="menu_top one_point">
{(props.point.arrimg.length!==0)?<SmallImages arr={props.point.arrimg} count={2} setCur={props.setCur} />:''}
{address}
{ (props.point.phone===null)?'':
		<div className="one_point_top_wrapper one_point_wrapper">
			<div className="one_point_big_text">Телефон:</div>
<div className="one_point_big_text">{props.point.phone}</div>
		</div>
}
{ (props.point.work===null)?"":<Work work={props.point.work} />}
{ (props.point.partnerId===null)?'':<Partner partners={props.partners} id={props.point.partnerId} />}
			<div className="one_point_top_wrapper one_point_filters">
					<div className="one_point_filters_title">Принимает на утилизацию</div>
	<ScrollableDivWithScroll items={filters} />
			</div>
{ (props.point.description===null || props.point.description==='')?'':
			<div className="one_point_top_wrapper">
				<div className="one_point_description_title">Описание</div>
				<div className="one_point_description_text" dangerouslySetInnerHTML={{__html: returnTextDescription(props.point.description)}}></div>
			</div>
}
	</div>	

</div>
	)
}

function Main(props) {
//new
const [filters,setFilters]=useReducer(filterReducer,{items:[]})
const [arrFilters, setArrFilters]=useState(false)
const [arrAllFilters, setArrAllFilters]=useState(false)
const [partners,setPartners]=useReducer(filterReducer,{items:[]})
const [arrPartners, setArrPartners]=useState(false)
const [arrAllPartners, setAllArrPartners]=useState(false)
const [partnersFire,setPartnersFire]=useState(false)
const [menu,setMenu]=useReducer(menuReducer,{isClose:true,openPoint:false,openFilters:true,openPartners:false})
const refSlider=useRef(null) //for animate closing slider
const refMenu1=useRef(null)
const refMenu2=useRef(null)
const refMenu3=useRef(null)
//end of new
const setVisibleError = useErrorHandler()
//const [allpoints, setAllpoints]=useState(false) //апи не оптимально, поэтому содержит информацию по всем точкам. этого запроса можно было бы избежать
const [gpoints, setGpoints]=useState(false) //points for map
//const [city, setCity]=useState(false)
//const [topic, setTopic]=useState(false)
//const [points, setPointsCount]=useState(false) //count of points current city and current topic
const pointsRef=useRef(null);
const [apoints, setApoints]=useState(false) //array of current point, used for slider
const [popupPosition,setPopupPosition]=useReducer(popupReducer,{visible:false,bottom:0,left:0,width:0,tip:'',anim:false,filter:''})
//const popupRef=useRef(null)
//const pointScroll=useRef(null);
//const [showPointInfo,setShowPointInfo]=useState(false)
//const [pointCurrentScroll, setPointCurrentScroll]=useState('')
//const [currentPointCategory,setCurrentPointCategory]=useState(false) //for current point category

const [showSlider,setShowSlider]=useState(false)
const [current,setCurrent]=useState('0') //current  image for init slider
const [mobilePanel,setMobilePanel]=useState('init')
const [showMobileHint,setShowMobileHint]=useState(false)
const [size,setSize]=useState({w:0,h:0})
const mobile=(window.innerWidth<=768 || isMobile)?true:false;

//api for events, yandex metrica
const apiEventsSend=(props)=>
{
const ua = navigator.userAgent.toLowerCase()
const isMobile=(/iphone/.test(ua))?'iOS':(/android/.test(ua))?'Android':'PC'
const defaultProps=
{
event_name:'No_set',
impact_task:(apoints!==false)?apoints.tip:'Нет данных',
impact_theme:(apoints!==false)?apoints.topic:'Нет данных',
impact_title:(apoints!==false)?apoints.name:'Нет данных',
impact_description:(apoints!==false)?apoints.description:'Нет данных',
/*current_city:(city!==false)?city.name:'Нет данных',*/
/*current_country:(city!==false)?city.fullName.split(', ')[0]:'Нет данных',*/
//current_task:(currentPointCategory!==false && points!==false)?returnNamebyPointType(points,currentPointCategory):'Все',
//current_theme:(topic!==false)?topic.name:'Нет данных',
device_type:isMobile
}
props=Object.assign({}, defaultProps, props);
const target=props.event_name
//for some events we use all of array, for another only part of array
let sendArr=(props.event_name.indexOf('impact_map_element')===-1)?{event_name:props.event_name,current_city:props.current_city,current_country:props.current_country,current_task:props.current_task,current_theme:props.current_theme,device_type:props.device_type}:props
delete sendArr.event_name
  if (typeof window.ym!=='undefined') window.ym(96208165, 'reachGoal', target, sendArr); 
//console.log(sendArr)
}
//end events for api

const showPointPopup=(e,id,mergedIds)=> //show point information
{
  if (typeof e==='undefined' || e===null) return false
  	  //console.log(e,id,mergedIds)
getPoint(setApoints,parseInt(id),pointsRef.current,mergedIds)
setMenu({type:'show_point'})
/*window.setTimeout(function() {
setShowPointInfo(true)
setMobilePanel('point')
},1000)*/
}

const checkClick=(e)=> //for hide mobile panel, menu,...
{
var event = window.event ? window.event : e
let timer;
const cn=event.target.className;
//console.log(cn)
  if (cn!=='item' && cn.indexOf('select_main')===-1  && cn.indexOf('select_main_edit')===-1 && cn.indexOf('select_main_edit_image')===-1  && cn.indexOf('select_category')===-1  && cn.indexOf('select_input')===-1 && popupPosition.visible!==false) 
  {
 //setPopupPosition({type:'set_visible',visible:false});
setPopupPosition({type:'set_animation',anim:true,visible:false})
  window.clearTimeout(timer)
timer=window.setTimeout(()=>setPopupPosition({type:'set_animation',anim:false}),300);
}
  if (cn==='main main_mobile' || typeof cn==='undefined' || cn==='') 
   {
setMobilePanel(mobilePanel+'_collapse')
//setShowPointInfo(false)
   }
	
}

	
let init=false
let initRef=useRef(init)
useEffect(() => { //init cities, type of points and type of topics
i_resize()
setSize({w:window.innerWidth,h:window.innerHeight})
  if (initRef.current===false)  //in some situations React can fire event twice, in dev mode
   {
initRef.current=true
const itemsFilter=[];
setFilters({type:'init',arr:itemsFilter})
getFilters(setArrFilters)
//set list of all filters
getAllPartners(setAllArrPartners)
//getPoint(setApoints,1,pointsRef.current) //for debug

props.setPopup({showPointPopup})
//props.setClosePoint({setShowPointInfo})
const setDefaultLocation=()=>
{
props.setCity({longitude:55.755819,latitude:37.617644,zoom:13}) //по-умолчанию Москва	
}
const setLocation=(pos)=>
{
  const crd = pos.coords;
props.setCity({longitude:crd.latitude,latitude:crd.longitude,zoom:13})
}
    if (!navigator.geolocation) 
     {
setDefaultLocation()
     }
     else
     {
 navigator.geolocation.getCurrentPosition(setLocation, setDefaultLocation);
     }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
useEffect(() => { //get partners
setPartners({type:'init',arr:[]})
getPartners(setArrPartners,filters)
}, [filters]);

useEffect(() => { //get list of all filters
const arr=arrFilters
let rez=[]
  for (let property in arr) 
   {
arr[property].points.map((item)=>item.owner=property)
	
  rez=[...rez,...arr[property].points]
   }
  if (rez.length===0) rez=false
setArrAllFilters(rez)
}, [arrFilters]);
  
  useEffect(() => { // //get points for map  when application started
    if (arrAllFilters!==false) getGlobalPoints(setGpoints,null,null,arrAllFilters)
  }, [arrAllFilters]);
      useEffect(() => { //get points, when active partners is changed
  if (partnersFire===false) return
 getGlobalPoints(setGpoints,filters.items,partners.items,arrAllFilters)
   }, [partnersFire])
/*
  useEffect(() => { //disable animation when filter
  if (popupPosition.anim===true)
   {
setPopupPosition({...popupPosition,anim:false})
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [popupFilter]);
*/






//update for map parent component
  useEffect(() => { 
  if (gpoints!==false)
   {
props.setGpoints(gpoints)
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [gpoints]);
/*
useEffect(() => { 
  if (city!==false)
   {
props.setCity(city)
//apiEventsSend({event_name:"impact_map_city_selection"})
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [city]);
*/
/*
useEffect(() => { //get ref of current points for map
  if (points!==false)
   {
pointsRef.current=points;
props.setPoints(points)
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [points]);
*/
/*useEffect(() => { //get ref of current popup position of menu
  if (popupPosition!==false)
   {
popupRef.current=popupPosition;
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [popupPosition]);*/
//end update for map parent component

//add handlers for check click (mobile)
//useEvent('click', checkClick)
useEvent('mousedown',checkClick)
//

const i_resize=(e)=>
{
const wh=window.innerHeight, ww=window.innerWidth
document.documentElement.style.setProperty('--app-height', wh+'px');
document.documentElement.style.setProperty('--app-width', ww+'px');
setSize({w:ww,h:wh})
window.scrollTo(0,0)
  if (popupPosition.visible)
   {
    if (!mobile)
     {
setPopupPosition({type:'set_animation',visible:false,anim:false})
document.activeElement.blur()
     }
     else //на самой мобильной версии это не нужно, но на десктопе тоже может быть маленький экран при ресайзе
      {
const temp_ref=document.getElementsByClassName('select_main_ref_mobile')
	  if (typeof temp_ref!=='undefined' && temp_ref.length>0 && temp_ref[0].offsetWidth) setPopupPosition({type:'set_width',width:temp_ref[0].offsetWidth})
      }
   }
}
//add handler for resize
useEvent('resize', i_resize)
//
//open slider after click
const openSlider=(id)=>
{
	/*console.log(id)*/
setMobilePanel('slider')
//setShowPointInfo(false)
setCurrent(id)
setShowSlider(true)
window.parent.postMessage('map_slider_open','*')
}

const closeSlider=()=>
{
apiEventsSend({event_name:"impact_map_element_picture_close_click"});
refSlider.current.classList.add('wrapper_gallery_hide')
delay(300).then(()=>{
setShowSlider(false);
//refSlider.current.classList.remove('wrapper_gallery_hide')
setMobilePanel('point');
window.parent.postMessage('map_slider_close','*')
})

}

const closeMenu=(ref,isPoint)=>
{
//если есть активная точка на карте то снимем активность
const el=document.getElementsByClassName('map_selected')
  if (el.length>=1) 
   {
el[0].classList.add('map_div')
el[0].classList.remove('map_selected')
   }
//
ref.classList.add('menu_anim_hide');
    if (showSlider) closeSlider();
delay(300).then(()=>{
ref.classList.remove('menu_anim_hide');
  if (isPoint)
   {
setMenu({type:'close_point'})
   }
  else setMenu({type:'switch_close'})
	})
}
/*
  if (mobile)
  { 
return (
    <OurContext.Provider value={{popupPosition,setPopupPosition}}>
	<div className="main main_mobile">
<ApiMode />
{(mobilePanel==='init')?<Screen1Mobile set={()=>{apiEventsSend({event_name:"mpact_map_hint_hide_click"});setMobilePanel('default');setShowMobileHint(true);window.setTimeout(()=>{setShowMobileHint(false)},4000)}} />:''}
<PopupMenu  />
<MobilePanel i_resize={i_resize} apiEventsSend={apiEventsSend} setCurrentPointCategory={setCurrentPointCategory} apoints={apoints} setCur={openSlider} size={size} visible={popupPosition.visible} tip={popupPosition.tip} popup={popup} state={mobilePanel} set={setMobilePanel} arrimg={apoints.arrimg} city={city} topic={topic} points={points}  />
<div className={classNames("touch_to_hide",{"hide":!showMobileHint})} onClick={()=>{setMobilePanel(mobilePanel+'_collapse')}}>Коснитесь карты чтобы<br />скрыть панель поиска</div>
<SliderMobile apiEventsSend={apiEventsSend} city={city} current={current} aimg={apoints.arrimg} mode={((mobilePanel==='slider')?'slider':'thumb')} show={(mobilePanel==='slider' || mobilePanel==='slider_thumb')} set={setMobilePanel} close={()=>{setMobilePanel('point');setShowSlider(false);window.parent.postMessage('map_slider_close','*');}} title={apoints.name} subtitle={apoints.description}  />
	</div>
</OurContext.Provider>
)
    }
    else*/
  return (
    <OurContext.Provider value={{popupPosition,setPopupPosition}}>
<div>
	<div className="main">
<ApiMode />
{/*	<Map x={city.latitude} y={city.longitude} arr={gpoints} icons={points} popup={(e,id)=>showPointPopup(e,id)} />*/}
<div className="menu_closed">
	<img src="./i/menu.svg" alt="" className="close_button" onClick={()=>setMenu({type:'switch_close'})} />
</div>
{
(menu.openPoint)?
	<div className="menu menu_point"  ref={refMenu3}>
<Screen3 point={apoints} filters={arrAllFilters} partners={arrAllPartners}  setCur={openSlider} setClose={()=>closeMenu(refMenu3.current,true)} setMenu={setMenu}  />
	</div>
	:''
}
{
(menu.openFilters && !menu.isClose)?
	<div className="menu menu_filters" ref={refMenu1}>
<Screen1 arrFilters={arrFilters} filters={filters} setFilters={setFilters} setClose={()=>closeMenu(refMenu1.current)}setMenu={setMenu} setGpoints={setGpoints} getPartners={getPartners} setPartners={setPartners} setArrPartners={setArrPartners} arrAllFilters={arrAllFilters}  />
	</div>
	:''
}
{
(menu.openPartners && !menu.isClose)?
	<div className="menu menu_partners" ref={refMenu2}>
<Screen2 setPartnersFire={setPartnersFire} showPointPopup={showPointPopup} setCity={props.setCity} currentPoints={props.currentPoints} arrAllPartners={arrAllPartners} arrPartners={arrPartners} partners={partners} setPartners={setPartners} setClose={()=>closeMenu(refMenu2.current)} setMenu={setMenu} setGpoints={setGpoints} filters={filters} arrFilters={arrAllFilters} setFilters={setFilters}  />
	</div>
	:''
}
</div>
</div>
<Slider ref={refSlider} apiEventsSend={apiEventsSend} current={current} aimg={apoints.arrimg} show={showSlider}  close={closeSlider} title={apoints.tip} subtitle={apoints.name} height={1} />

</OurContext.Provider>
  );
}
//<Screen1 arrFilters={arrFilters} filters={filters} setFilters={setFilters} />
export default Main;